import React, { useState, useEffect } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton,
} from 'react-accessible-accordion';

import Breadcrumb from '../../components/Breadcrumb';
import CTA from '../../components/CTA';

import bgImg from '../../assets/img/cta/cta-bg-2.png';
import { useCart } from '../../context/CartContext';


const HomeMain = () => {
	const [loginOpen, setLoginOpen] = useState(false)
	const [cuponOpen, setCuponOpen] = useState(false)
	const { cartItems, getCartTotal } = useCart()


	return (
		<main>


			<section className="checkout-area pt-200 pb-70">
				<div className="container">
					<form action="#">
						<div className="row">
							<div className="col-lg-6">
								<div className="checkbox-form">
									<h3>Shipping Details</h3>
									<div className="row">
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>First Name <span className="required">*</span></label>
												<input type="text" placeholder="" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>Last Name <span className="required">*</span></label>
												<input type="text" placeholder="" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<label>Company Name</label>
												<input type="text" placeholder="" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<label>Address <span className="required">*</span></label>
												<input type="text" placeholder="Street address" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<input type="text" placeholder="Apartment, suite, unit etc. (optional)" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="checkout-form-list">
												<label>Town / City <span className="required">*</span></label>
												<input type="text" placeholder="Town / City /" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>State / County <span className="required">*</span></label>
												<input type="text" placeholder="" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>Postcode / Zip <span className="required">*</span></label>
												<input type="text" placeholder="Postcode / Zip" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>Email Address <span className="required">*</span></label>
												<input type="email" placeholder="" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="checkout-form-list">
												<label>Phone <span className="required">*</span></label>
												<input type="text" placeholder="Postcode / Zip" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-6">
								<div className="your-order mb-30 ">
									<h3>Your order</h3>
									<div className="your-order-table table-responsive">
										<table>
											<thead>
												<tr>
													<th className="product-name">Product</th>
													<th className="product-total">Total</th>
												</tr>
											</thead>
											<tbody>
												{cartItems.map((item) => (
													<tr key={item.id} className="cart_item">
														<td className="product-name">
															Frame ({item.size}) - {item.color} <strong className="product-quantity"> × 1</strong>
														</td>
														<td className="product-total">
															<span className="amount">₹{item.price}</span>
														</td>
													</tr>
												))}
											</tbody>
											<tfoot>
												<tr className="cart-subtotal">
													<th>Cart Subtotal</th>
													<td><span className="amount">₹{getCartTotal()}</span></td>
												</tr>
												<tr className="shipping">
													<th>Shipping</th>
													<td>
														<ul>
															<li>
																<input type="radio" name="shipping" />
																<label>
																	Flat Rate: <span className="amount">₹50.00</span>
																</label>
															</li>
															<li>
																<input type="radio" name="shipping" />
																<label>Free Shipping:</label>
															</li>
														</ul>
													</td>
												</tr>
												<tr className="order-total">
													<th>Order Total</th>
													<td><strong><span className="amount">₹{getCartTotal() + 50}</span></strong>
													</td>
												</tr>
											</tfoot>
										</table>
									</div>

									<div className="coupon-accordion">

											<div id="checkout_coupon" className="coupon-checkout-content">
												<div className="coupon-info">
													<form action="#">
														<p className="checkout-coupon">
															<input type="text" placeholder="Coupon Code" />
															<button className="it-btn" type="submit">Apply Coupon</button>
														</p>
													</form>
												</div>
											</div>
									</div>


									<div className="payment-method">
										<div className="order-button-payment mt-20">
											<button type="submit" className="it-btn">Place order</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>

		</main>
	);
}

export default HomeMain;