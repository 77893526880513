import React from 'react';
import About from './AboutSection';
import Banner from './HomeTwoBanner';
import Social from './SocialSection';
import Service from './ServiceSection'
import Project from './ProjectSection';
import Testimonial from './TestimonialSection';
import Brand from '../../components/Brand';
import Blog from './BlogSection';
import Video from './VideoSection';
import Contact from '../../components/Contact';
import FramePricing from '../../components/FramePricing';

const HomeTwoMain = () => {
	return (
		<main>
         <Banner />
         <FramePricing />
         <Service />

         <About />

       

      </main>
	);
}

export default HomeTwoMain;