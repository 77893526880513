import React from 'react';
import { Link } from 'react-router-dom';

const FramePricing = () => {
    const frameSizes = [
        {
            size: "5\" x 7\"",
            price: "530",
            icon: "fal fa-rectangle-portrait"
        },
        {
            size: "6\" x 8\"",
            price: "399",
            icon: "fal fa-rectangle-landscape"
        },
        {
            size: "8\" x 6\"",
            price: "399",
            icon: "fal fa-rectangle-wide"
        },
        {
            size: "8\" x 8\"",
            price: "504",
            icon: "fal fa-square"
        },
        {
            size: "12\" x 8\"",
            price: "504",
            icon: "fal fa-rectangle-wide"
        },
        {
            size: "8\" x 12\"",
            price: "682",
            icon: "fal fa-rectangle-portrait"
        }
    ];

    return (
        <div className="price__area price__plr price__space grey-bg">
            <div className="container-fluid">
                <div className="row align-items-end mb-60">
                    <div className="col-xl-12 col-lg-12 wow animate__fadeInRight" data-wow-duration="1.1s">
                        <div className="price__top-text">
                            <h4 className="text-center mb-0 char-anim-2">Choose from our range of frame sizes</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {frameSizes.map((frame, index) => (
                        <div 
                            key={index} 
                            className="col-6 col-xs-6 col-sm-6 col-md-4 col-xl-2 mb-40 wow animate__fadeInUp" 
                            data-wow-duration="1.1s"
                        >
                            <div className="price__item text-center">
                                <div className="price__top-content">
                                    <div className="price__icon">
                                        <span><i className={frame.icon}></i></span>
                                    </div>
                                    <div className="price__text">
                                        <h5 className="price__title-sm"><a href="#">{frame.size}</a></h5>
                                    </div>
                                </div>
                                <div className="price__content-list">
                                    <ul>
                                        <li>₹{frame.price}</li>
                                    </ul>
                                </div>
                                <div className="price__button">
                                    <Link 
                                        className="main-btn tp-btn-hover alt-color-black" 
                                        to="/design"
                                    >
                                        <span>Design Frame</span>
                                        <b></b>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FramePricing; 