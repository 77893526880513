import React, { useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import CTA from '../../components/CTA';
import { useCart } from '../../context/CartContext';
import { useNavigate } from 'react-router-dom';
import bgImg from '../../assets/img/cta/cta-bg-2.png';
import { Link } from 'react-router-dom';

const CartMain = () => {
	const { cartItems, removeFromCart, getCartTotal } = useCart();
	const navigate = useNavigate();

	const handleEditItem = (item) => {
		// Store the item being edited in localStorage
		localStorage.setItem('editingItem', JSON.stringify(item));
		navigate('/design');
	};

	return (
		<main>
			{/* <Breadcrumb pageTitle="Cart" /> */}
			<section className="cart-area pt-200 pb-100">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-8">
							<div className="cart-items-container">
								{cartItems.map((item) => (
									<div key={item.id} className="cart-item mb-3 p-3 bg-white rounded-4 shadow-sm">
										<div className="d-flex align-items-center">
											<div 
												className="cart-item-image rounded-3"
												style={{
													width: '120px',
													height: '120px',
													backgroundImage: `url(${item.image})`,
													backgroundSize: 'cover',
													backgroundPosition: 'center',
													flexShrink: 0,
													border: `solid 5px ${item.color.toLowerCase()}`
												}} 
											/>
											<div className="ms-4 flex-grow-1">
												<div className="d-flex justify-content-between align-items-start">
													<div>
														<h5 className="mb-2 fw-semibold">Frame</h5>
														<p className="mb-2 text-muted">
															{item.size} • {item.color} • {item.finish}
														</p>
													</div>
													<div className="d-flex gap-2">
														<button 
															className="btn btn-outline-primary btn-sm"
															onClick={() => handleEditItem(item)}
														>
															<i className="fa fa-edit"></i>
														</button>
														<button 
															className="btn btn-outline-danger btn-sm"
															onClick={() => removeFromCart(item.id)}
														>
															<i className="fa fa-trash"></i>
														</button>
													</div>
												</div>
												<div className="mt-2">
													<span className="fw-semibold fs-5">₹{item.price}</span>
												</div>
											</div>
										</div>
									</div>
								))}

								{cartItems.length === 0 && (
									<div className="text-center py-5">
										<i className="fa fa-shopping-cart fa-3x mb-3 text-muted"></i>
										<h4>Your cart is empty</h4>
										<p className="text-muted mb-4">Looks like you haven't added anything to your cart yet.</p>
										<Link to="/design" className="add-to-cart">
											Continue Shopping
										</Link>
									</div>
								)}
							</div>
						</div>

						<div className="col-12 col-lg-4">
							{cartItems.length > 0 && (
								<div className="cart-summary bg-white rounded-4 shadow-sm p-4 sticky-top" style={{ top: '20px' }}>
									<h4 className="mb-4">Order Summary</h4>
									
									<div className="summary-items mb-3">
										{cartItems.map(item => (
											<div key={item.id} className="d-flex justify-content-between mb-2">
												<span className="text-muted">Frame ({item.size})</span>
												<span>₹{item.price}</span>
											</div>
										))}
									</div>
									
									<hr className="my-3" />
									
									<div className="d-flex justify-content-between align-items-center mb-4">
										<h5 className="mb-0">Total</h5>
										<h5 className="mb-0">₹{getCartTotal()}</h5>
									</div>

									<Link 
										className="btn w-100 rounded-3 py-3 mb-3 add-to-cart"
										to="/checkout"
									>
										Proceed to Checkout
									</Link>
									
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
			{/* <CTA ctaBG={bgImg} /> */}
		</main>
	);
}

export default CartMain;